import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 1,
  KTOTEST = 8285,
  KTOMAIN = 2559
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const INIT_CODE_HASHES = {
  [ChainId.MAINNET]: '',//
  [ChainId.KTOTEST]: '0x457a34d2a5e1c064e5b28b4de86cd9c47ebb8d80b70817fc65d937c4479b40a1',//
  [ChainId.KTOMAIN]: '0x0083fe5d336d7307a0e3893242ebbbca569059660a79c60b2086c3728d752eed',//
  // [ChainId.KTOMAIN]: '0x8450accc4be4889251f4a3d66847f92600669b85f886ae5f4727360e93c6d7c9',//
}

export const FACTORY_ADDRESS = {
  [ChainId.MAINNET]: '',//
  [ChainId.KTOTEST]: '0x4B6B91Ee2A8e6F43D2FcEC3fCB69481961843137',//
  [ChainId.KTOMAIN]: '0xa8264364c4b267cC0574D17C7a64D3FEFAB17ea3',//
  // [ChainId.KTOMAIN]: '0x64F8EE581C74e29c968A1321f651B2c5CA6d1332',//
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
// export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
